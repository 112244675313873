<template>
  <div>
    <div>
      <b-form-row>
        <b-col sm="10" md="6" lg="5" xl="4">
          <b-form-group>
            <div class="floating-input-field">
              <b-form-input
                :id="`form-name`"
                :class="{
                  'is-invalid': (!formObject.formName && addFormSubmit) || formNameExistError,
                }"
                type="text"
                placeholder=" "
                v-model.trim="formObject.formName"
                required
              ></b-form-input>
              <label :for="`form-name`">Form name</label>
              <b-form-invalid-feedback class="d-block" v-if="!formObject.formName && addFormSubmit">Form name required.</b-form-invalid-feedback>
              <b-form-invalid-feedback class="d-block" v-if="formObject.formName && formNameExistError && addFormSubmit"
                >Form name already exists.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="10" md="6" lg="5" xl="4">
          <b-form-group>
            <div class="floating-select-field">
              <b-form-select
                id="form-category"
                class="form-control"
                v-model="formObject.categoryName"
                @input="formObject.categoryName = $event"
                :class="{
                  'is-value-exist': formObject.categoryName != null,
                }"
              >
                <b-form-select-option value="">None</b-form-select-option>
                <b-form-select-option v-for="(category, cIndex) in widgetDetails.formBuilderWidgetSettings.categoryLst" :key="cIndex" :value="category.name">{{
                  category.name
                }}</b-form-select-option>
              </b-form-select>
              <label :for="`form-category`">Form category</label>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col>
          <b-form-group>
            <a class="theme-font-medium" href="javascript:void(0)" id="add-edit-form-category" @click="addFormCategory"><PlusBlueIcon /> Edit/Add categories</a>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="10" md="6" lg="5" xl="4">
          <b-form-group>
            <div class="floating-input-field">
              <b-form-input
                :id="`form-url`"
                :class="{
                  'is-invalid': (!formObject.formUrl && addFormSubmit) || (!validateUrl(formObject.formUrl) && addFormSubmit),
                }"
                type="text"
                placeholder=" "
                v-model="formObject.formUrl"
                required
              ></b-form-input>
              <label :for="`form-url`">Form URL</label>
              <b-form-invalid-feedback class="d-block" v-if="!formObject.formUrl && addFormSubmit">Form URL required.</b-form-invalid-feedback>
              <b-form-invalid-feedback class="d-block" v-if="formObject.formUrl && addFormSubmit && !validateUrl(formObject.formUrl)"
                >Invalid url.</b-form-invalid-feedback
              >
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="10" md="6" lg="5" xl="4">
          <b-form-group>
            <div class="floating-input-field">
              <b-form-input
                :id="`public-key`"
                :class="{
                  'is-invalid': !formObject.publicKey && addFormSubmit,
                }"
                type="text"
                placeholder=" "
                v-model="formObject.publicKey"
                required
              ></b-form-input>
              <label :for="`public-key`">Public key</label>
              <b-form-invalid-feedback class="d-block" v-if="!formObject.publicKey && addFormSubmit">Public key required.</b-form-invalid-feedback>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row>
        <b-col sm="10" md="6" lg="5" xl="4">
          <b-form-group>
            <div class="floating-input-field">
              <b-form-input
                :id="`private-key`"
                :class="{
                  'is-invalid': !formObject.privateKey && addFormSubmit,
                }"
                type="text"
                placeholder=" "
                v-model="formObject.privateKey"
                required
              ></b-form-input>
              <label :for="`private-key`">Private key</label>
              <b-form-invalid-feedback class="d-block" v-if="!formObject.privateKey && addFormSubmit">Private key required.</b-form-invalid-feedback>
            </div>
          </b-form-group>
        </b-col>
      </b-form-row>
      <b-form-row class="mb-4">
        <b-col>
          <b-button type="button" id="add-form-btn" v-activeBlur variant="primary" @click="addEditFormToWidget"
            >{{ editFormIndex > -1 ? 'Edit form' : 'Add form' }}
          </b-button>
          <b-button type="button" id="reset-form-btn" v-activeBlur @click="resetForm" class="ml-3" variant="outline-secondary">Reset</b-button>
        </b-col>
      </b-form-row>
    </div>
    <b-form-row>
      <b-col>
        <div class="b-table-sticky-header custom-scroll">
          <table class="table" id="form-list-table">
            <thead>
              <tr>
                <th>Form name</th>
                <th>Public key</th>
                <th>Private key</th>
                <th colspan="2">Category</th>
              </tr>
            </thead>
            <tbody>
              <template v-if="widgetDetails.formBuilderWidgetSettings.forms.length">
                <tr v-for="(form, foIndex) in widgetDetails.formBuilderWidgetSettings.forms" :key="foIndex">
                  <td>{{ form.formName }}</td>
                  <td>{{ form.publicKey }}</td>
                  <td>{{ form.privateKey }}</td>
                  <td>{{ form.categoryName }}</td>
                  <td class="text-right pr-0 py-2">
                    <b-dropdown boundary="window" class="action-dropdown" toggle-class="btn-h-44" no-caret variant="outline-secondary" right no-flip>
                      <template #button-content>
                        Actions
                        <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
                      </template>
                      <b-dropdown-item title="Edit form" href="javascript:void(0)" @click="editForm(form, foIndex)">Edit</b-dropdown-item>
                      <b-dropdown-item title="Delete form" href="javascript:void(0)" @click="deleteForm(foIndex)">Delete</b-dropdown-item>
                    </b-dropdown>
                  </td>
                </tr>
              </template>
              <template v-else>
                <tr>
                  <td colspan="5" class="text-center">No forms available.</td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <b-form-invalid-feedback class="d-block mb-2" v-if="!widgetDetails.formBuilderWidgetSettings.forms.length && formSubmitted"
          >Atleast one form required.</b-form-invalid-feedback
        >
      </b-col>
    </b-form-row>
    <div class="form-status-section mt-4">
      <div>
        <b-form-row>
          <b-col cols="12">
            <h4 class="page-sub-header">Add form status</h4>
          </b-col>
          <b-col sm="10" md="6" lg="4" xl="4">
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input
                  :id="`status-name`"
                  :class="{
                    'is-invalid': (!statusObject.statusName && addStatusSubmit) || statusNameExistError,
                  }"
                  type="text"
                  placeholder=" "
                  v-model.trim="statusObject.statusName"
                  required
                ></b-form-input>
                <label :for="`status-name`">Status name</label>
                <b-form-invalid-feedback class="d-block" v-if="!statusObject.statusName && addStatusSubmit">Status name required.</b-form-invalid-feedback>
                <b-form-invalid-feedback class="d-block" v-if="statusObject.statusName && statusNameExistError && addStatusSubmit"
                  >Status name already exists.</b-form-invalid-feedback
                >
              </div>
            </b-form-group>
          </b-col>
          <b-col sm="10" md="6" lg="4" xl="4">
            <b-form-group>
              <div class="floating-input-field">
                <b-form-input
                  :id="`status-color`"
                  :class="{
                    'is-invalid': (!statusObject.color && addStatusSubmit) || !isValidColor,
                  }"
                  type="text"
                  placeholder=" "
                  v-model="statusObject.color"
                  @focus="showColorPicker = true"
                  @blur="showColorPicker = false"
                  @input="applyColorToPicker"
                  required
                ></b-form-input>
                <label :for="`status-color`">Status color</label>
                <color-picker
                  v-if="showColorPicker"
                  theme="dark"
                  :color="statusObject.color"
                  :sucker-hide="true"
                  @input="statusObject.color = $event.hex"
                  @changeColor="statusObject.color = $event.hex"
                  class="color-picker"
                />
              </div>
              <b-form-invalid-feedback class="d-block" v-if="!statusObject.color && addStatusSubmit">Status color required.</b-form-invalid-feedback>
              <b-form-invalid-feedback :state="isValidColor">Invalid color</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
          <b-col sm="10" md="6" lg="4" xl="4" class="mt-md-2 pt-md-1" v-if="statusObject.statusName && statusObject.color">
            <b-form-group>
              <span class="preview-status" :style="{ backgroundColor: statusObject.color }">
                {{ statusObject.statusName }}
              </span>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row class="mb-4">
          <b-col>
            <b-button type="button" v-activeBlur id="add-status-btn" variant="primary" @click="addEditStatusToWidget"
              >{{ editStatusIndex > -1 ? 'Edit' : 'Add' }}
            </b-button>
            <b-button type="button" v-activeBlur id="reset-status-btn" @click="resetStatus" class="ml-3" variant="outline-secondary">Reset</b-button>
          </b-col>
        </b-form-row>
      </div>
      <b-form-row>
        <b-col>
          <div class="b-table-sticky-header custom-scroll">
            <table class="table" id="status-list-table">
              <thead>
                <tr>
                  <th>Status name</th>
                  <th>Status color</th>
                  <th colspan="2">Preview</th>
                </tr>
              </thead>
              <tbody>
                <template v-if="widgetDetails.formBuilderWidgetSettings.statusLst.length">
                  <tr v-for="(status, sIndex) in widgetDetails.formBuilderWidgetSettings.statusLst" :key="sIndex">
                    <td>{{ status.statusName }}</td>
                    <td>{{ status.color }}</td>
                    <td>
                      <span class="preview-status" :style="{ backgroundColor: status.color }">
                        {{ status.statusName }}
                      </span>
                    </td>
                    <td class="text-right pr-0 py-2">
                      <b-dropdown boundary="window" class="action-dropdown" toggle-class="btn-h-44" no-caret variant="outline-secondary" right no-flip>
                        <template #button-content>
                          Actions
                          <BIconChevronDown class="pt-1" variant="dark" scale="0.65" />
                        </template>
                        <b-dropdown-item title="Edit status" href="javascript:void(0)" @click="editStatus(status, sIndex)">Edit</b-dropdown-item>
                        <b-dropdown-item title="Delete status" href="javascript:void(0)" @click="deleteStatus(sIndex)">Delete</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr>
                    <td colspan="4" class="text-center">No status available.</td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <b-form-invalid-feedback class="d-block mb-2" v-if="!widgetDetails.formBuilderWidgetSettings.statusLst.length && formSubmitted"
            >Atleast one status required.</b-form-invalid-feedback
          >
        </b-col>
      </b-form-row>
    </div>
    <b-form-row class="mt-3">
      <b-col cols="12">
        <b-form-group>
          <b-form-checkbox
            :disabled="isDefaultCulture"
            id="form-show-search-bar"
            name="form-show-search-bar"
            :switch="true"
            v-model="widgetDetails.formBuilderWidgetSettings.showSearchBar"
          >
            Show search bar
          </b-form-checkbox>
        </b-form-group>
      </b-col>
    </b-form-row>
    <b-sidebar
      id="add-edit-categories"
      body-class="custom-scroll"
      v-model="addFormCategoryFg"
      aria-labelledby="Add/Edit categories"
      aria-label="Add/Edit categories"
      shadow
      bg-variant="white"
      :backdrop-variant="`dark`"
      no-close-on-esc
      no-close-on-backdrop
      lazy
      backdrop
      right
      width="25vw"
    >
      <template #header="{}">
        <h3 class="font-21 theme-font-medium mb-0">Edit/Add categories</h3>
        <div class="sidebar-header-close">
          <a href="javascript:void(0)" aria-labelledby="Edit/Add categories title" @click="closeAddEditCategory"
            ><img src="../../../assets/img/close.png" alt="close-tab"
          /></a>
        </div>
      </template>
      <template #default="{}">
        <b-form-row v-for="(category, fCIndex) in widgetDetails.formBuilderWidgetSettings.categoryLst" :key="fCIndex">
          <b-col>
            <b-form-group>
              <div class="d-flex">
                <div class="floating-input-field">
                  <b-form-input
                    :id="`form-category-name-${fCIndex}`"
                    :class="{
                      'is-invalid': category.error,
                    }"
                    type="text"
                    placeholder=" "
                    v-model.trim="category.name"
                    @input="validateCategoryExist(category.name, fCIndex)"
                    required
                  ></b-form-input>
                  <label :for="`form-category-name-${fCIndex}`">Category name</label>
                </div>
                <b-button type="button" v-activeBlur @click="deleteFormCategory(fCIndex, category.name)" variant="outline-secondary" class="ml-3 w-48"
                  ><img src="../../../assets/img/close.png" alt="close-tab"
                /></b-button>
              </div>
              <b-form-invalid-feedback class="d-block" v-if="category.error">Form category name already exists.</b-form-invalid-feedback>
            </b-form-group>
          </b-col>
        </b-form-row>
        <b-form-row>
          <b-col>
            <b-form-group>
              <b-button type="button" v-activeBlur block class="btn-dot text-left" variant="outline-secondary" @click="addNewFormCategory"
                >Add new category</b-button
              >
            </b-form-group>
          </b-col>
        </b-form-row>
      </template>
      <template #footer="{}">
        <b-button type="button" v-activeBlur class="btn-h-44" variant="outline-secondary" @click="closeAddEditCategory">Close</b-button>
      </template>
    </b-sidebar>
    <ConfirmationModal
      :showModal="showDeleteCategoryConfirmModal"
      :title="`CONFIRM`"
      message="FORM_BUILDER_WIDGET_CATEGORY_REMOVE"
      @onConfirm="confirmDeleteFormCategory($event, 'yes')"
      @closeConfirmModal="confirmDeleteFormCategory($event, 'no')"
    />
    <ConfirmationModal
      :showModal="showDeleteFormConfirmModal"
      :title="`CONFIRM`"
      message="FORM_BUILDER_WIDGET_FORM_REMOVE"
      @onConfirm="confirmDeleteForm"
      @closeConfirmModal="showDeleteFormConfirmModal = false"
    />
    <ConfirmationModal
      :showModal="showDeleteStatusConfirmModal"
      :title="`CONFIRM`"
      message="FORM_BUILDER_WIDGET_STATUS_REMOVE"
      @onConfirm="confirmDeleteStatus"
      @closeConfirmModal="showDeleteStatusConfirmModal = false"
    />
  </div>
</template>
<script>
import { mapState } from 'vuex'
import { BIconChevronDown } from 'bootstrap-vue'
import colorPicker from '@caohenghu/vue-colorpicker'
import { useValidateFields } from '@/composables/useValidateFields'
import cloneDeep from 'lodash/cloneDeep'
import { WidgetDashboardCountList } from '../../../utilities/constants'
import PlusBlueIcon from '../../../assets/svg/plus-blue.svg'
export default {
  name: 'FormBuilderWidget',
  props: ['widgetDetails', 'isEditPage', 'formSubmitted', 'defaultCulture'],
  setup() {
    const { validateUrl } = useValidateFields()

    return { validateUrl }
  },
  components: {
    colorPicker,
    PlusBlueIcon,
    BIconChevronDown,
    ConfirmationModal: () => import('../../common/ConfirmationModal.vue'),
  },
  data() {
    return {
      widgetDashboardCountList: WidgetDashboardCountList,
      addFormCategoryFg: false,
      editFormCategoryIndex: -1,
      addFormCategorySubmit: false,
      showDeleteCategoryConfirmModal: false,
      editFormIndex: -1,
      deleteFormIndex: -1,
      editFormNameClone: null,
      formObject: {
        id: 0,
        formName: null,
        categoryName: null,
        formUrl: null,
        publicKey: null,
        privateKey: null,
      },
      formNameExistError: false,
      addFormSubmit: false,
      showDeleteFormConfirmModal: false,
      editStatusIndex: -1,
      deleteStatusIndex: -1,
      editStatusNameClone: null,
      statusObject: {
        id: 0,
        statusName: null,
        color: null,
      },
      statusNameExistError: false,
      addStatusSubmit: false,
      showDeleteStatusConfirmModal: false,
      showColorPicker: false,
    }
  },
  computed: {
    isDefaultCulture() {
      return this.widgetDetails.culture !== this.defaultCulture
    },
    isValidColor() {
      if (this.statusObject.color) {
        return this.statusObject.color.match(/^#[a-f0-9]{6}$/i) !== null
      }
      return true
    },
    ...mapState({
      selectedTenant: (state) => state.common.selectedTenant,
    }),
  },
  mounted() {},
  methods: {
    addFormCategory() {
      this.addFormCategoryFg = true
      this.addFormCategorySubmit = false
      this.editFormCategoryIndex = -1
    },
    addNewFormCategory() {
      this.widgetDetails.formBuilderWidgetSettings.categoryLst.push({
        id: 0,
        name: null,
      })
    },
    deleteFormCategory(index, value) {
      this.editFormCategoryIndex = index
      if (value) {
        this.showDeleteCategoryConfirmModal = true
      } else {
        this.confirmDeleteFormCategory(undefined, 'no')
      }
    },
    confirmDeleteFormCategory(event, type) {
      this.showDeleteCategoryConfirmModal = false
      if (event === 'close') {
        return
      }
      const deleteCategory = cloneDeep(this.widgetDetails.formBuilderWidgetSettings.categoryLst[this.editFormCategoryIndex])
      this.widgetDetails.formBuilderWidgetSettings.categoryLst.splice(this.editFormCategoryIndex, 1)
      if (type === 'yes') {
        this.widgetDetails.formBuilderWidgetSettings.forms = this.widgetDetails.formBuilderWidgetSettings.forms.filter(
          (category) => category.categoryName !== deleteCategory.name
        )
      } else {
        this.widgetDetails.formBuilderWidgetSettings.forms.forEach((form) => form.categoryName === deleteCategory.name && ((form.categoryName = null), true))
      }
    },
    closeAddEditCategory() {
      if (!this.widgetDetails.formBuilderWidgetSettings.categoryLst.some((c) => c.error)) {
        this.addFormCategoryFg = false
        this.widgetDetails.formBuilderWidgetSettings.categoryLst = this.widgetDetails.formBuilderWidgetSettings.categoryLst.filter((c) => c.name)
      }
    },
    validateCategoryExist(name, fIndex) {
      this.addFormCategorySubmit = true
      this.widgetDetails.formBuilderWidgetSettings.categoryLst[fIndex].error = false
      this.editFormCategoryIndex = fIndex
      if (
        name &&
        this.widgetDetails.formBuilderWidgetSettings.categoryLst
          .filter((c, index) => index !== fIndex)
          .some((category) => category.name.toLowerCase() === name.toLowerCase())
      ) {
        this.widgetDetails.formBuilderWidgetSettings.categoryLst[fIndex].error = true
      }
      if (
        this.formObject.categoryName &&
        !this.widgetDetails.formBuilderWidgetSettings.categoryLst.some((category) => category.name.toLowerCase() === this.formObject.categoryName.toLowerCase())
      ) {
        this.formObject.categoryName = null
      }
    },
    resetForm() {
      this.addFormSubmit = false
      this.formNameExistError = false
      this.formObject = {
        id: 0,
        formName: null,
        categoryName: null,
        formUrl: null,
        publicKey: null,
        privateKey: null,
      }
      this.editFormIndex = -1
      this.editFormNameClone = null
    },
    editForm(form, index) {
      this.formNameExistError = false
      this.editFormIndex = index
      this.editFormNameClone = cloneDeep(form.formName)
      this.formObject = Object.assign({}, form)
    },
    deleteForm(index) {
      this.deleteFormIndex = index
      this.showDeleteFormConfirmModal = true
    },
    confirmDeleteForm() {
      this.showDeleteFormConfirmModal = false
      this.widgetDetails.formBuilderWidgetSettings.forms.splice(this.deleteFormIndex, 1)
      if (this.editFormIndex === this.deleteFormIndex) {
        this.resetForm()
      }
    },
    addEditFormToWidget() {
      this.addFormSubmit = true
      this.formNameExistError = false
      if (this.formObject.formName && this.validateUrl(this.formObject.formUrl) && this.formObject.publicKey && this.formObject.privateKey) {
        this.editFormIndex = this.editFormNameClone
          ? this.widgetDetails.formBuilderWidgetSettings.forms.findIndex((form) => form.formName.toLowerCase() === this.editFormNameClone.toLowerCase())
          : this.editFormIndex
        if (
          this.editFormIndex === -1 &&
          this.widgetDetails.formBuilderWidgetSettings.forms.some((form) => form.formName.toLowerCase() === this.formObject.formName.toLowerCase())
        ) {
          this.formNameExistError = true
          return
        } else if (
          this.widgetDetails.formBuilderWidgetSettings.forms
            .filter((f, index) => index !== this.editFormIndex)
            .some((form) => form.formName.toLowerCase() === this.formObject.formName.toLowerCase())
        ) {
          this.formNameExistError = true
          return
        }
        if (this.editFormIndex > -1) {
          this.widgetDetails.formBuilderWidgetSettings.forms[this.editFormIndex] = this.formObject
        } else {
          this.widgetDetails.formBuilderWidgetSettings.forms.push(this.formObject)
        }
        this.formObject = null
        this.resetForm()
        this.addFormSubmit = false
      }
    },
    resetStatus() {
      this.addStatusSubmit = false
      this.statusNameExistError = false
      this.statusObject = {
        id: 0,
        statusName: null,
        color: null,
      }
      this.editStatusIndex = -1
      this.editStatusNameClone = null
    },
    editStatus(status, index) {
      this.statusNameExistError = false
      this.editStatusIndex = index
      this.editStatusNameClone = cloneDeep(status.statusName)
      this.statusObject = Object.assign({}, status)
    },
    deleteStatus(index) {
      this.deleteStatusIndex = index
      this.showDeleteStatusConfirmModal = true
    },
    confirmDeleteStatus() {
      this.showDeleteStatusConfirmModal = false
      this.widgetDetails.formBuilderWidgetSettings.statusLst.splice(this.deleteStatusIndex, 1)
      if (this.editStatusIndex === this.deleteStatusIndex) {
        this.resetStatus()
      }
    },
    addEditStatusToWidget() {
      this.addStatusSubmit = true
      this.statusNameExistError = false
      if (this.statusObject.statusName && this.statusObject.color && this.statusObject.color.match(/^#[a-f0-9]{6}$/i) !== null) {
        this.editStatusIndex = this.editStatusNameClone
          ? this.widgetDetails.formBuilderWidgetSettings.statusLst.findIndex(
              (status) => status.statusName.toLowerCase() === this.editStatusNameClone.toLowerCase()
            )
          : this.editStatusIndex
        if (
          this.editStatusIndex === -1 &&
          this.widgetDetails.formBuilderWidgetSettings.statusLst.some(
            (status) => status.statusName.toLowerCase() === this.statusObject.statusName.toLowerCase()
          )
        ) {
          this.statusNameExistError = true
          return
        } else if (
          this.widgetDetails.formBuilderWidgetSettings.statusLst
            .filter((s, index) => index !== this.editStatusIndex)
            .some((status) => status.statusName.toLowerCase() === this.statusObject.statusName.toLowerCase())
        ) {
          this.statusNameExistError = true
          return
        }
        if (this.editStatusIndex > -1) {
          this.widgetDetails.formBuilderWidgetSettings.statusLst[this.editStatusIndex] = this.statusObject
        } else {
          this.widgetDetails.formBuilderWidgetSettings.statusLst.push(this.statusObject)
        }
        this.statusObject = null
        this.resetStatus()
        this.addStatusSubmit = false
      }
    },
    applyColorToPicker() {
      this.showColorPicker = false
      this.$nextTick(() => {
        this.showColorPicker = true
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.preview-status {
  background-color: rgb(99, 101, 109);
  border-radius: 20px;
  padding: 0.25rem 1rem;
}
.form-status-section {
  background: #f5f5f5;
  border-radius: 4px;
  padding: 1.5rem;
}
</style>
